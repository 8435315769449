import React from "react"
import {useState} from "react"
import { Link } from "gatsby"

import Layout from "../layouts"
import SEO from "../components/seo"
import SendForm from "../components/send-form"

// import StyledHtml from "../components/styled-html"


const ContactdPage = (props) => {
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [message, setMessage] = useState()
  const [hasBeenSent, setHasBeenSent] = useState(false)
  return (
    <Layout location={props.location}>
      <SEO title="Contact me" />
      
      <div className="bg-gray-100 h-screen">
          {/* Start of main */}
          <div className="flex flex-wrap md:h-screen bg-gray-100">
            <div className="flex flex-col bg-white w-full md:w-8/12 pt-3 pb-0 pl-3 pr-3 sm:pl-6 sm:pr-6">
              {/* Start of Navigation */}
              <div className="flex-none sticky top-0 z-50 bg-white border-b-2 border-gray-50">

                <div className="grid grid-flow-col grid-cols-3 grid-rows-1 gap-0 align-middle pb-1 pt-4">
                  <div className="flex-none flex align-middle self-center">
                    <div>
                      <Link to={"/"}>
                        {/* <span className="hover:text-blue-150 hover:underline"><FormattedMessage id="LABEL_BACK" /></span>
                      <br/> */}
                        <svg xmlns="http://www.w3.org/2000/svg" className="opacity-70 pr-2 hover:text-blue-150 inline w-10 stroke-current stroke-0 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" viewBox="0 0 24 24" fill="currentColor" strokeLinecap="round" strokeLinejoin="round">

                          <path d="M15.6,1.7c1.5,0.2,2.3,1.7,2.3,3.4c-0.2,1.7-1.3,3.2-2.8,3c-1.5-0.2-2.3-1.7-2.3-3.4S14.1,1.5,15.6,1.7z M8.8,1.7
    c1.5,0,2.5,1.5,2.5,3.2S10.1,8,8.8,8S6.3,6.5,6.3,4.9S7.5,1.7,8.8,1.7z M1.6,9.7c-0.2-1.5,0.6-3,1.9-3.2S6,7.3,6.3,9
    c0.2,1.5-0.6,3-1.9,3.2C3.1,12.3,1.8,11.2,1.6,9.7z M16,20.3c-1.1,0-2.5-1.1-4.2-1.1s-2.5,1.1-4,1.1c-1.7,0-3.4-1.5-3.4-3.6
    c0-1.5,1.7-3,2.8-3.8c0.2-0.2,1.7-2.3,2.1-2.5c0.8-0.6,1.3-1.1,2.5-1.1c1.5,0,2.5,0.6,3.2,1.5c0.4,0.4,1.1,1.7,1.3,1.9
    c0.8,0.8,2.8,2.1,3,4C19.6,19.1,17.7,20.3,16,20.3z M22.4,10.2c-0.2,1.5-1.7,2.8-3,2.3c-1.3-0.2-2.1-1.7-1.9-3.4
    c0.2-1.5,1.7-2.8,3-2.3C21.8,7,22.6,8.5,22.4,10.2z"/>
                        </svg>

                      </Link>

                    </div>

                    <div className="hidden lg:inline h-11">
                      <div className="text-left text-xs flex self-center" >
                        <div className="font-bold pr-6">
                          <Link to={"/"}>
                            <span className="uppercase">
                              CONTACT ME</span>
                            <br />
                            <span className="font-normal antialiased text-xs">Leave your message here</span>

                          </Link>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>            
                {/* Form content     */}
              <div className="flex flex-col my-auto"> 
              {!hasBeenSent && (       
              <form className="w-full max-w-lg mx-auto">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                      First Name<span className="text-orange-500">*</span>
                    </label>
                    <input 
                      value={firstName}
                      onChange={(e)=>setFirstName(e.target.value)}
                      className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-500" 
                      id="grid-first-name" 
                      type="text" 
                      placeholder="Jane"/>
                    <p className="text-orange-500 text-xs italic">Please fill out this field.</p>
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" 
                      for="grid-last-name">
                      Last Name
                    </label>
                    <input 
                      value={lastName}
                      onChange={(e)=>setLastName(e.target.value)}
                      className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-orange-500" 
                      id="grid-last-name" 
                      type="text"
                       placeholder="Doe"/>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                      E-mail<span className="text-orange-500">*</span>
                    </label>
                    <input 
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                      className="appearance-none block w-full bg-gray-100 text-gray-700 border border-orange-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-500" 
                      id="email" 
                      type="email"/>
                    <p className="text-orange-500 text-xs italic">Please fill out this field.</p>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                      Message<span className="text-orange-500">*</span>
                    </label>
                    <textarea 
                      value={message}
                      onChange={(e)=>setMessage(e.target.value)}
                      className=" no-resize appearance-none block w-full bg-gray-100 text-orange-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-500 h-48 resize-none"
                      id="message"></textarea>
                    <p className="text-orange-500 text-xs italic">Please fill out this field.</p>
                  </div>
                </div>
                <div className="md:flex md:items-center">
                  <div className="md:w-1/3">
                    {/* <button className="lg:text-base sm:text-sm text-xs mb-2 lg:mt-8 mt-3 pl-4 pr-4 focus:outline-none border-2 border-blue-50 rounded-full font-bold text-blue-150 px-2 py-2 hover:bg-white hover:border-blue-100 hover:text-blue-150 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105" type="button">
                      Send
                    </button> */}
                    <SendForm firstName={firstName} lastName={lastName} email={email} message={message} onSending={setHasBeenSent}/>
                  </div>
                  <div className="md:w-2/3"></div>
                </div>
              </form>
              )}
              {hasBeenSent && (
                <div className="mx-auto">Thank you, {firstName}! Message has successfully sent.</div>
                )
              }
              </div>
            </div> 
            {/* End of Main */}
            {/* Start of Description */}
            <div className="bg-gray-100 w-full md:w-4/12 md:h-max p-3 sm:p-6 flex shadow-inner">

            <div className="flex flex-col">
                {/* { this.state.selected.desc_image && ( */}
                    <div className="flex-none ">
                      <>
                      {/* {this.state.selected.desc_image_text && !Array.isArray(this.state.selected.desc_image_text) && ( */}
                          <p className="antialiased pt-1 lg:text-base text-sm">
                            {/* <StyledHtml html={"image text"}  /> */}
                            <span className="text-xs uppercase font-bold">About me</span>
                            {/* <h1><span className="text-xs">Location</span></h1> */}
                            {/* <p>I live in Finland, Espoo.</p> */}
                      
                          </p>
                          <p className="antialiased pt-1 lg:text-base text-sm">
                            {/* <StyledHtml html={"image text"}  /> */}
                            
                            {/* <h1><span className="text-xs">Professional experience</span></h1> */}
                            <p>As an experienced UX designer, I am passionate about creating intuitive and user-centered digital experiences that engage, delight, and solve real problems.</p>
                          
              
                          
                          
                          </p>
                          <p className="antialiased pt-1 lg:text-base text-sm">
                            {/* <StyledHtml html={"image text"}  /> */}
                            
                            {/* <h1><span className="text-xs">Let's keep in touch</span></h1> */}
                        
                            <p> Looking forward to the opportunity to connect.
  </p>
                    
                        
                          
                          
                          </p>
                          <p className="antialiased lg:text-base text-sm pt-2">
                            {/* <StyledHtml html={"image text"}  /> */}
                            
                        
                        
                          
                            <h1><span className="text-xs">Sincerely, Maria</span></h1>
                        
                        
                          
                          
                          </p>
                        {/* )} */}

                        {/* `{this.state.selected.desc_image_text && Array.isArray(this.state.selected.desc_image_text) &&
                          this.state.selected.desc_image_text.map((text, idx) => */}
                            {/* ( */}
                              {/* <StyledHtml key={`desctxt${1}`} classes="antialiased pt-1 lg:text-base text-sm pb-2" html={"text"} /> */}

                          {/* ))
                        } */}
                      <div className="mx-auto" style={{ maxWidth: `300px`, maxHeight: `300px`, marginBottom: `1rem`, marginTop: `` }}>
                        {/* Image */}
                        {/* <StoryImage
                            alt={`${this.props.data.name} description`} filename={`${this.props.data.name}/${this.state.selected.desc_image}`} /> */}
                      </div>
                      </>
                  </div>
                  {/* )} */}
                  {/* <div className={this.state.selected.desc_image?"flex-initial top-0":"flex-initial my-auto"}> */}
                  <div className="flex-initial top-0">
                    {/* {!Array.isArray(this.state.selected.description) && ( */}
                      <p className="antialiased pt-1 lg:text-base text-sm">
                      
                        {/* desc */}
                      </p>
                    {/* )}  */}

                    {/* {Array.isArray(this.state.selected.description) &&
                      this.state.selected.description.map((text, idx) =>
                        ( */}
                          {/* <StyledHtml key={`desc${2}`} classes="antialiased pt-1 lg:text-base text-sm pb-2" html={"Main text"}/> */}
                      {/* ))
                    } */}

                    {/* { this.state.selected.bottom_desc_image && ( */}
                        <div className="flex-none ">
                          <div className="mx-auto" style={{ maxWidth: `300px`, maxHeight: `300px`, marginBottom: `1rem`, marginTop: `` }}>
                            {/* Image 2 */}
                          {/* <StoryImage
                            alt={`${this.props.data.name} description`} filename={`${this.props.data.name}/${this.state.selected.bottom_desc_image}`} /> */}
                        </div>
                      </div>
                      {/* )} */}
                </div>  

              
              </div>
            </div>
            {/* End of Description */}
          </div>

        </div>

    </Layout>
  )
}

export default ContactdPage


import React from "react"
import { useState } from "react";

const SendForm = ({firstName, lastName, email, message, onSending}) => {
    const [sent, setSent]=useState(false)

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

	const submit=async ()=>{
        if(!(firstName || email || message)){
            alert("First name, email and message are required to fill out!")
            return
        }
        if(!validateEmail(email)){
            alert(`Email "${email}" is not valid!`)
            return
        }
        let resp;
        try{
            resp = await fetch('https://budarevskaya.pro/send', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: "same-origin", // include, *same-origin, omit
                headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "origin-when-cross-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({firstName, lastName, email, message})}
            )
        }catch(error){
            alert('Unable to send form. Try again later')
        }

        if(resp?.ok){
            setSent(true)
            onSending(true)
        }else{
            alert('Unable to send form. Try again later')
        }
    }
	return (
        <>        
        {!sent && (<button onClick={()=>submit()} className="lg:text-base sm:text-sm text-xs mb-2 lg:mt-8 mt-3 pl-4 pr-4 focus:outline-none border-2 border-blue-50 rounded-full font-bold text-blue-150 px-2 py-2 hover:bg-white hover:border-blue-100 hover:text-blue-150 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105" type="button">
            Send
        </button>)}
        </>
    )
	
};

export default SendForm